@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/cme/src/scss/utility/_burger.scss";
@import "/Users/alivevn/Website/vhost/cme/src/scss/utility/_flex.scss";
@import "/Users/alivevn/Website/vhost/cme/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/cme/src/scss/utility/_mixin.scss";


.notfound-block { 
	padding: 100px 0; 
	.cmn-title {
		margin: 0 0 20px;
	}
	.cmn-txt {
		text-align: center;
	}
	.cmn-btn {
		margin: 0 auto;
	}
}