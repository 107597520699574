/* argument */
$colortext : #343434; 
$color_blue: #003682;
$color_yellow: #FFA515;
$main_color: #343434;

$browser_prefix: ("-webkit-", "-moz-", "-ms-", "-o-", "");
@mixin add_prefix ($key, $value) {
	@each $prefix in $browser_prefix {
		#{$prefix}$key: $value;
	}
}

/* font style */
@mixin ffMon {
	font-family: 'Montserrat', sans-serif;
}

/* site style */
@mixin text-hide{
	overflow: hidden;
	text-indent: -9999px;
}


/* [ opacity ]
-------------------------------------------------*/
@mixin opacity($num){
	-ms-filter: alpha(opacity=$num); /* IE8 */
	opacity: $num/100;
	//display: inline-block\9;
}

/* [ display style ]
-------------------------------------------------*/
@mixin display-table{
	display: table;
	> *{
		display: table-cell;
		vertical-align: middle;
	}
}
@mixin height-match{
	display: block;
	text-align: center;
	> *{
		vertical-align: middle;
		display: inline-block;
	}
	&:before{
		content:"";
		height: 100%;
		vertical-align: middle;
		width: 0;
		display: inline-block;
	}
}


/* [ background-image ]
-------------------------------------------------*/
@mixin bgimg($url,$rep:repeat,$pos:left top,$color:none,$size:auto) {
	background-image: url($url);
	background-repeat: $rep;
	background-position: $pos;
	background-color: $color;
	background-size: $size;
}


/* [ writing-mode ]
-------------------------------------------------*/
@mixin writing-mode( $orientation:'h', $direction:'rl' ) {
	@if $orientation == 'v' {
		// Vertical with direction, rl by default
		-ms-writing-mode: tb- + $direction; // IE
		@each $prefix in $browser_prefix {
			#{$prefix}writing-mode: vertical- + $direction;
		}
	} @else {
		// Default...
		-ms-writing-mode: lr-tb; // IE
		@each $prefix in $browser_prefix {
			#{prefix}writing-mode: horizontal-tb;
		}
	}
}

/* [ illustrator & photoshop letter spacing ]
-------------------------------------------------*/
@function letter-spacing( $letter-spacing ){
	@return ($letter-spacing/ 1000) * 1em;
}
@mixin letter-spacing( $target ){
	letter-spacing: letter-spacing( $target );
}


/* [ easy breakpoint ]
-------------------------------------------------*/
// @mixin PC {
// 	@media (min-width: 897px) and (orientation: landscape),
// 		(min-width: 768px) and (orientation: portrait) {
// 		@content;
// 	}
// }

// @mixin SP {
// 	@media (max-width: 896px) and (orientation: landscape),
// 		(max-width: 767px) and (orientation: portrait) {
// 		@content;
// 	}
// }

/* [ easy breakpoint ]
-------------------------------------------------*/
@mixin PC {
	@include min-screen(768px) {
		@content;
	}
}

@mixin SP {
	@include max-screen(767px) {
		@content;
	}
}

@mixin android {
	@include max-screen(360px) {
		@content;
	}
}

@mixin ip5 {
	@include max-screen(320px) {
		@content;
	}
}
/* [ easy transform ]
-------------------------------------------------*/
@mixin transform($transforms) {
	@include add_prefix(transform, $transforms);
}

// Author: Kyohei Maeda

//aspect-ratio fixed box
//==================================================
@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before{
		display: block;
		content: " ";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

//font-size with rem
//==================================================
@mixin font-size($size: 14) {
	font-size: $size + px;
	font-size: ($size / 10)  + rem;
}

@mixin my_menu_effect($items, $delay, $direct) {
	@each $item in $items {
		&:nth-child(#{$item}) {
			@include transition_c(#{$delay}ms, 'delay');
		}
		@if ($direct == "open") {
			$delay: $delay + 50;
		} @else {
			$delay: $delay - 50;
		}
	}
}

@mixin transition_c ($content, $opt: "") {
	@each $prefix in $browser_prefix {
		@if ($opt == "") {
			#{$prefix}transition: $content;
		} @else {
			#{$prefix}transition-#{$opt}: $content;
		}
	}
}

@mixin transform_c ($content) {
	@each $prefix in $browser_prefix {
		#{$prefix}transform: $content;
	}
}

@mixin calc-css ($prefix, $content) {
	#{$prefix}: -webkit-calc($content);
	#{$prefix}: -moz-calc($content);
	#{$prefix}: calc($content);
}

@mixin border ($content, $side: "") {
	@if ($side == "") {
		border: #{$content};
	} @else {
		border-#{$side}: #{$content};
	}
}

@mixin border-radius ($radius, $side: "") {
	@if ($side == "" ) {
		@each $prefix in $browser_prefix {
			#{$prefix}border-radius: $radius;
		}
	} @else {
		@each $prefix in $browser_prefix {
			#{$prefix}border-#{$side}-radius: $radius;
		}
	}
}

@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

//==================================================
// Black and White Opacity Functions
// Call | .css { background: black(0.5); }
//==================================================
@function black($opacity) {
	@return rgba(black, $opacity)
}
@function white($opacity) {
	@return rgba(white, $opacity)
}

//==================================================
// Centering
// Call | .css { @include centerer; }
//==================================================

@mixin centerer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// If you want to be able to center in only one direction...

@mixin centerer($horizontal: true, $vertical: true) {
	position: absolute;
	@if ($horizontal and $vertical) {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if ($horizontal) {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if ($vertical) {
		top: 50%;
		transform: translate(0, -50%);
	}
}

//==================================================
// Triangle helper mixin
// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
// @param {Color} $color [currentcolor] - Triangle color 
// @param {Length} $size [1em] - Triangle size
// Call | .css::before { @include triangle(bottom, #000, 5px); }
//==================================================

@mixin triangle($direction, $color: currentcolor, $size: 1em, $distance: 5px) {
	@if not index(top right bottom left, $direction) {
		@error "Direction must be either `top`, `right`, `bottom` or `left`.";
	}

	width: 0;
	height: 0;
	content: '';
	z-index: 2;
	margin: $distance;
	border-#{opposite-position($direction)}: ($size * 1.5) solid $color;

	$perpendicular-borders: $size solid transparent;

	@if $direction == top or $direction == bottom {
		border-left:   $perpendicular-borders;
		border-right:  $perpendicular-borders;
	} @else if $direction == right or $direction == left {
		border-bottom: $perpendicular-borders;
		border-top:    $perpendicular-borders;
	}
}

// miltiple lines ellipsis
//==================================================
@mixin multiLineEllipsis($lineCount: 1){
	overflow: hidden;
	width: 100%;
	-webkit-line-clamp: $lineCount;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: normal;
}

// miltiple lines ellipsis for FF & IE
//==================================================
@mixin multiLineEllipsisForFF($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
	overflow: hidden;
	position: relative;
	line-height: $lineHeight;
	max-height: $lineHeight * $lineCount;
	text-align: justify;
	margin-right: -1.15em;
	padding-right: 1.15em;
	&:before {
		content: '…';
		position: absolute;
		right: 0;
		bottom: 0;
	}
	&:after {
		content: '';
		position: absolute;
		right: 0;
		width: 1.15em;
		height: 1em;
		margin-top: 0.2em;
		background: $bgColor;
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		text-justify: inter-cluster;
	}
}

@mixin excerpt($font-size: 16px, $line-height: 1.4, $lines-to-show: 3, $excerpt-bg: transparent) {
  background: $excerpt-bg;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  // Fallback for non-webkit
  @media screen and (min--moz-device-pixel-ratio:0), 
  screen and (-ms-high-contrast: active), 
  screen and (-ms-high-contrast: none) {
    overflow: hidden;
    position: relative;
    &:before {
      background: $excerpt-bg;
      bottom: 0;
      position: absolute;
      right: 0;
      float: right;
      content: '\2026';
      margin-left: -3rem;
      width: 3rem;
    }
    &:after {
      content: '';
      background: $excerpt-bg;
      position: absolute;
      height: 50px;
      width: 100%;
      z-index: 1;
    }
  }
}

// generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
// scale
@mixin scale($scale) {
	 @include transform(scale($scale));
}

@mixin font-line-spacing($fontSize: 12, $lineHeight: 20, $letterSpacing: 0) {
	font-size: $fontSize + px; //fallback for old browsers
	font-size: ($fontSize / 10)  + rem;
	line-height: $lineHeight + px; //fallback for old browsers
	line-height: ($lineHeight / $fontSize) + em;
	letter-spacing: ($letterSpacing / 1000) + em;
}
@mixin clamp($line: 2) {
	-webkit-line-clamp: $line;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-o-text-overflow: ellipsis;
	-webkit-text-overflow: ellipsis;
	text-overflow: ellipsis;
	overflow: hidden;
}
// box shadow 
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}
@mixin animation ($delay, $duration, $animation, $direction: normal, $fillmode: fowards, $infinite: infinite) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: $fillmode;
    -webkit-animation-direction: $direction;
    -webkit-animation-iteration-count: $infinite;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: $fillmode;
    -moz-animation-direction: $direction;
    -moz-animation-iteration-count: $infinite;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: $fillmode;
    animation-iteration-count: $infinite;
    animation-direction: $direction;
}